import React, { useState } from "react";
import { navigate } from "gatsby";

// seo
import Seo from "../../components/Seo";

// Local Storage
import localStorage from "../../utils/LocalStorageService";

// actions
import { dxLoginUser } from "../../actions/authActions";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import LoginForm from "../../components/DoctorLandingPage/LoginForm/LoginForm";

const DoctorLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const getLoginCredentialsData = async value => {
    try {
      setLoading(true);
      const loginBody = {
        email: value.email,
        password: value.password,
      };
      const response = await dxLoginUser(loginBody);

      localStorage.setToken(response);
      navigate("/doctor/doctor-portal");

      setLoading(false);
      setServerError("");
    } catch (error) {
      console.error("Error in doctor lp login:", error);
      setServerError("Please enter correct email id and password");
      setLoading(false);
    }
  };

  return (
    <div className={`bgDarkBlue`} style={{ minHeight: "100vh" }}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />
      <HeaderWithLogo logoPosition="center" />
      <LoginForm
        loading={loading}
        getLoginCredentialsData={getLoginCredentialsData}
        serverError={serverError}
      />
    </div>
  );
};

export default DoctorLoginPage;

import React from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

// css
import * as styles from "./LoginForm.module.css";

const LoginForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: "all" });

  // Login Form -> Call Submit button click
  const onSubmit = data => {
    props.getLoginCredentialsData(data);
  };

  return (
    <div className="container twinContainer">
      <div className={styles.loginForm__sec}>
        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`row ${styles.loginFormSec__form}`}>
            <div className={`col-12 ${styles.loginFormSec__para}`}>
              <h1>Sign In</h1>
              <h5>
                *Enter your registered credentials for doctor landing page
              </h5>
            </div>
            <div
              className={`col-12 form-group ${styles.loginFormSec__formInp}`}
            >
              <label htmlFor="email">Email*</label>
              <input
                className={
                  errors.email ? "form-control is-invalid" : "form-control"
                }
                id="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Please enter valid email address",
                  pattern: {
                    value:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                    message: "Entered value does not match email format",
                  },
                })}
                type="email"
              />
              {errors.email && (
                <span role="alert" className="invalid-feedback">
                  {errors.email.message}
                </span>
              )}
            </div>

            <div
              className={`col-12 form-group mt-3 ${styles.loginFormSec__formInp}`}
            >
              <label htmlFor="password">Password*</label>
              <input
                className={
                  errors.password ? "form-control is-invalid" : "form-control"
                }
                id="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: "Please enter your password",
                })}
                type="password"
              />
              {errors.password && (
                <span className="invalid-feedback">
                  {errors.password.message}
                </span>
              )}
            </div>
          </div>
          <div className={`${styles.loginFormSec__cta}`}>
            <button type="submit">
              <span className={props.loading && styles.loginFormSec__cta__txt}>
                Sign In
              </span>
              <span className={styles.loginFormSec__cta__loader}>
                <Loader
                  type="TailSpin"
                  color="#FFF"
                  height={30}
                  width={30}
                  radius={1}
                  visible={props.loading}
                />
              </span>
            </button>
            <span
              className={`d-block errorclass text-danger ${styles.loginFormSec__errorTxt}`}
            >
              {!isValid &&
                isSubmitted &&
                "Please fill all the fields correctly"}
            </span>
            {props.serverError && (
              <span
                className={`d-block text-danger ${styles.loginFormSec__errorTxt}`}
              >
                {props.serverError}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

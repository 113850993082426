// extracted by mini-css-extract-plugin
export var loginFormSec = "LoginForm-module--loginFormSec--5-X2B";
export var loginFormSec__cta = "LoginForm-module--loginFormSec__cta--jcYNT";
export var loginFormSec__cta__loader = "LoginForm-module--loginFormSec__cta__loader--xPt-K";
export var loginFormSec__cta__txt = "LoginForm-module--loginFormSec__cta__txt--Dun0S";
export var loginFormSec__errorTxt = "LoginForm-module--loginFormSec__errorTxt--y-odb";
export var loginFormSec__form = "LoginForm-module--loginFormSec__form--xjnjy";
export var loginFormSec__formInp = "LoginForm-module--loginFormSec__formInp---QT+9";
export var loginFormSec__logo = "LoginForm-module--loginFormSec__logo--8lcSL";
export var loginFormSec__para = "LoginForm-module--loginFormSec__para--w5tq7";
export var loginForm__sec = "LoginForm-module--loginForm__sec--gFVw-";
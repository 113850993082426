import axios from "axios";
import { navigate } from "gatsby";
import { apiCalls } from "./apiCalls";

// config
import config from "../config";

// utils
import { authBase64Encoding } from "../utils";

// Local Storage
import localStorage from "../utils/LocalStorageService";

// To login the user
export const loginUser = async (email, password, deviceId) => {
  try {
    const encodedAuthString = authBase64Encoding(email, password);

    const options = {
      method: "post",
      url: config.ICAP_PLATFORM_URL + "/api/jwt/authenticate",
      headers: {
        Authorization: `Basic ${encodedAuthString}`,
        "Content-Type": "application/json",
      },
      data: {
        deviceUUID: deviceId.toString(),
      },
    };

    const response = await axios(options);
    return response.data;
  } catch (error) {
    console.log("Icap login api, server err:----", error.response);
    if (error.response) {
      if (error.response.status === 401)
        throw new Error(error.response.data.message);
    } else {
      console.error("Icap login api, server err:", error.response.data.message);
      throw new Error(error.response.data.message);
    }
  }
};

// To logout the user
export const logoutUser = url => {
  localStorage.clearToken();
  navigate(url); // redirect to login page url
};

// Forgot Password Api Call
export const forgotPassword = async email => {
  try {
    let options = {
      method: "post",
      url: config.ICAP_PLATFORM_URL + "/api/client/auth/passwordReset",
      headers: {
        "Content-Type": "text/plain",
      },
      data: email,
    };
    await axios(options);
    return true;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Icap forgot password api, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// Restrict(logout) user to move back and forth when user clicks the browser back button
export const logoutOnBrowserBackButton = func => {
  window.history.pushState(null, null, window.location.pathname); // this is to add a new entry to the browser history so that the user can't go back
  window.addEventListener("popstate", func);

  return () => {
    window.removeEventListener("popstate", func);
  };
};

// DX -> Login User
export const dxLoginUser = async (data) => {
  const url = config.DX_API_BASE_URL + "/api/admin/token";

  try {
    const result = await apiCalls("post", url, data);
    return result.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error);
    } else {
      console.error("Dx Login Failure, server err:", error.message);
      throw new Error(error.message);
    }
  }
};
